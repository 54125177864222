<template>
    <!-- 设备下发记录 -->
    <div class="equipment-record">
        <div class="companySearch">
            <div>
                <el-button
                    @click="addFun"
                    class="titleButton"
                    type="primary"
                    >批量下发</el-button
                >
            </div>
            <div class="titleSearch">
                <el-select
                    class="gateSty"
                    v-model="examine"
                    placeholder="状态"
                    clearable
                >
                    <el-option
                        v-for="(item, index) in examines"
                        :key="index"
                        :label="item.name"
                        :value="item.id"
                    >
                    </el-option>
                </el-select>
                <el-input
                    @keyup.enter.native="searchClick"
                    clearable
                    v-model="input"
                    placeholder="点位名称/设备SN/人员姓名"
                ></el-input>
                <el-button
                    @click="searchClick"
                    class="titleButton"
                    type="primary"
                    icon="el-icon-search"
                    >搜索</el-button
                >
                <el-button class="titleButton" @click="failFun">
                    下发全部失败记录
                </el-button>
            </div>
        </div>
        <div class="companyTableC">
            <div class="companyTable">
                <el-table
                    v-loading="loading"
                    :data="pagingData.elements"
                    style="width: 100%"
                    height="100%"
                    @selection-change="handleSelectionChange"
                >
                    <el-table-column
                    type="selection"
                    width="46">
                    </el-table-column>
                    <el-table-column
                        prop="doorName"
                        label="点位名称"
                        min-width="100"
                    >
                    </el-table-column>
                    <el-table-column
                        prop="deviceSn"
                        label="设备SN"
                        min-width="190"
                    >
                    </el-table-column>
                    <el-table-column
                        prop="userName"
                        label="下发人员姓名"
                        min-width="110"
                    >
                    </el-table-column>
                    <el-table-column
                        label="人员类型"
                        min-width="80"
                    >
                        <template slot-scope="scope">
                            <div>
                                {{ scope.row.userType===1?'访客':'员工' }}
                            </div>
                        </template>
                    </el-table-column>
                    <el-table-column
                        prop="triggerAction"
                        label="触发动作"
                        min-width="80"
                    >
                    </el-table-column>
                    <el-table-column
                        prop="updatedAt"
                        label="更新时间"
                        min-width="160"
                    >
                    </el-table-column>
                    <el-table-column
                        prop="successTime"
                        label="下发成功时间"
                        min-width="160"
                    >
                    </el-table-column>
                    <el-table-column
                        prop="logStr"
                        label="日志"
                        min-width="160"
                    >
                    </el-table-column>
                    <el-table-column label="状态" min-width="60">
                        <template slot-scope="scope">
                            <div class="stateSty" :class="{stateSty2:scope.row.state!==1}">
                                {{ scope.row.state===1?'成功':'失败' }}
                            </div>
                        </template>
                    </el-table-column>
                </el-table>
            </div>
            <!-- 分页 -->
            <el-pagination
                :current-page="currentPage"
                :page-size="pagingData.pageSize"
                @current-change="handleCurrentChange"
                background
                layout="total, prev, pager, next"
                :total="pagingData.totalRecords"
            >
            </el-pagination>
        </div>
    </div>
</template>
<script>

export default {
    name: 'equipment-record',
    components: {},
    data() {
        return {
            loading: false,
            // 状态
            examine: null,
            examines: [
                { name: '失败', id: 2 },
                { name: '成功', id: 1 },
            ],
            input: '',
            // 设备下发表格数据
            pagingData: {},
            // 分页-总条数
            totalNum: null,
            // 当前页数
            currentPage: 1,
            //列表-开始位置
            start: 1,
            // 复选 数组
            multipleSelection:[],
        }
    },
    created() {
        // 设备下发列表
        this.comtanyList()
    },
    methods: {
        // 当复选发生变化时
        handleSelectionChange(val) {
            this.multipleSelection = val;
        },
        // 设备下发列表
        comtanyList() {            
            this.loading = true
            this.$instance
                .post('/v1/issue/list', {
                        pageNum: this.currentPage - this.start,
                        pageSize: 10,
                        // 状态
                        state: this.examine,
                        // 搜索关键字
                        search: this.input,
                        // 人员ID
                        // userId,
                })
                .then((res) => {
                    if (res.data.status === 200) {
                        this.loading = false
                        this.pagingData = res.data.data
                        this.pagingData.elements.map((val) => {
                            // JSON转化
                            let logStr=JSON.parse(val.logStr)
                            if(logStr.message){
                                val.logStr=logStr.message
                            }
                        })
                    }
                })
                .catch((error) => {
                    console.log('error: ', error)
                })
        },
        // 点击 搜索
        searchClick() {
            // 当前页数
            this.currentPage = 1
            this.comtanyList()
        },
        // 点击分页-当前页
        handleCurrentChange(val) {
            this.currentPage = val
            // 搜索并拉取列表
            this.comtanyList()
        },
        // 点击 批量下发
        addFun() {
            let idStr=''
            let idStrArray=[]
            this.multipleSelection.map(val=>{
               idStrArray.push(val.id)
            })
            idStr=idStrArray.toString()
            console.log('idStr: ', idStr);
            this.$instance
                .post('/v1/issue/batchIssue', {
                        idStr,
                })
                .then((res) => {
                    if (res.data.status === 200) {
                        this.$message({
                            message:res.data.message,
                            type: 'success',
                        })
                    }
                })
                .catch((error) => {
                    console.log('error: ', error)
                })
        },
        // 点击 下发全部失败记录
        failFun(){
            this.$instance
                .post('/v1/issue/batchIssueFail')
                .then((res) => {
                    if (res.data.status === 200) {
                        this.$message({
                            message:res.data.message,
                            type: 'success',
                        })
                    }
                })
                .catch((error) => {
                    console.log('error: ', error)
                })
        },
    },
}
</script>
<style lang="scss" scoped>
.equipment-record {
    width: calc(100% - 40px);
    margin: 20px;
    background-color: #fff;
    // 搜索
    & ::v-deep .companySearch {
        width: 100%;
        height: 64px;
        display: flex;
        flex-wrap: wrap;
        align-items: center;
        justify-content: space-between;
        padding: 0px 20px;
        box-sizing: border-box;
        border-bottom: 1px #f0f0f0 solid;
        .el-button--primary {
            line-height: 32px;
            padding: 0 12px;
            border: unset;
        }
        .titleButton {
            line-height: 30px;
            padding: 0 12px;
        }
        .titleSearch {
            display: flex;
            flex-wrap: wrap;
            .gateSty > .el-input {
                width: 120px !important;
            }
            .el-select > .el-input,
            .el-input {
                margin-right: 6px;
            }
            .el-input {
                width: 220px;
                .el-input__inner {
                    height: 32px;
                    line-height: 32px;
                }
                .el-input__suffix {
                    i {
                        line-height: 32px;
                    }
                }
            }
        }
    }
    .companyTableC {
        width: calc(100% - 40px);
        height: calc(100% - 144px);
        margin: 20px;
        // 表格
        & ::v-deep .companyTable {
            height: 100%;
            border: 1px #f0f0f0 solid;
            overflow: auto;
            .buttonC {
                padding-right: 10px;
            }
            .el-table td,
            .el-table th {
                height: 49px;
                padding: 4px 0;
                .stateSty{
                    color:#35a435;
                }
                .stateSty2{
                    color:#de3131;
                }
                .el-dropdown {
                    margin: 0 8px;
                }
            }
        }
        // 翻页
        .el-pagination {
            text-align: right;
            margin-top: 10px;
            padding: unset;
        }
    }
}
</style>
